(function () {
  'use strict';

  class Topics {
    constructor(TopicsRest, $cookies, $rootScope) {
      this.TopicsRest = TopicsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    topicsIndex(topics = {}, callback = angular.noop) {
      return this.TopicsRest.topics().get(topics,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    topicsCreate(topics = {}, callback = angular.noop) {
      return this.TopicsRest.topics().save(topics,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    myTopics(topic = {}, callback = angular.noop) {
      return this.TopicsRest.myTopics().get(topic,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    topicView(topic = {}, callback = angular.noop) {
      return this.TopicsRest.topic().get(topic,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    topicEdit(topic = {}, callback = angular.noop) {
      return this.TopicsRest.topic().update(topic,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    topicDelete(topic = {}, callback = angular.noop) {
      return this.TopicsRest.topic().remove(topic,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lomMetadata(lomMetadata = {}, callback = angular.noop) {
      return this.TopicsRest.lomMetadata().get(lomMetadata,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Topics
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Topics', Topics);
}());
